import React from 'react';
import StandardLayout from '../layouts/standard';

export default function BlogCategoryPage({ pageContext }) {
    const { title, id } = pageContext;
    return <StandardLayout>
        <div>
            <h1>Blog Category: {title}</h1>
            <h3>ID: {id}</h3>
        </div>
    </StandardLayout>
}